<template>
  <div class="text-center py-10">
    <template v-if="joinError">
      <div class="red--text my-4">
        {{ joinError }}
      </div>
    </template>
    <template v-else>
      <div v-if="storeIdFromStorage && storeName">
        <h1>您已經是{{ storeName }}成員</h1>
      </div>

      <div v-else>
        <div v-if="hasJoined">
          <div v-if="joinSuccessfully && store">
            <h1>您已成功加入{{ store.name }}</h1>
          </div>
        </div>
      </div>
    </template>



  </div>
</template>
<script>
export default {
  mounted() {
    // if(!this.storeIdFromStorage) {
    //   this.join()
    // }
    this.join() //暫時移除判斷
  },
  data: () => ({
    hasJoined: false,
    joinSuccessfully: false,
    store: null,
    joinError: null,
  }),
  methods: {
    async join() {
      try {
        const params = {
          code: this.code,
          provider_id: this.providerId
        }
        const res = await this.$api.collection.storeApi.join(this.storeId, this.providerId, params)
        const { token, store } = res
        this.joinSuccessfully = true
        this.store = store
        this.$tokenStore.set(token)
        await this.readMemberProfile()
        this.$snotify.success('即將跳轉至商品列表頁', `您已成功加入: ${store.name}`)
        this.redirectToProductList()
      } catch (error) {

        if (error.data) {
          let { code, message, store } = error.data
          if (code) {
            code = parseInt(code)
            switch (code) {
              case 1: {
                this.joinError = `您已是 ${this.storeName} 的成員`
                this.$snotify.error(this.joinError, '加入失敗')
                console.log(message);
                break
              }
              case 2: {
                this.joinError = `此連結已失效，請再次邀請`
                this.$snotify.error(this.joinError, '加入失敗')
                console.log(message);
                break
              }
              default:
                this.joinError = 'cannot understand the code'
                this.$snotify.error(error, '加入失敗')
                console.log('cannot understand the code');
            }
          } else {
            this.joinError = 'cannot find the code'
            this.$snotify.error('cannot find the code', '加入失敗')
            console.log(error);
          }
        } else {
          this.joinError = 'data 內無可用參數'
          this.$snotify.error('data 內無可用參數', '加入失敗')
          console.log(error);
        }

        console.warn(error)

      } finally {
        this.hasJoined = true
      }
    },
    async readMemberProfile() {
      try {
        const data = await this.$api.collection.memberApi.profile()
        this.$store.dispatch(`member/set`, data)
      } catch (error) {
        console.warn(error)
      }
    },
    async redirectToProductList() {
      await this.$helper.delay(2)
      this.$router.push({ name: 'store-product-list' })
      await this.$helper.delay(0.2)
      window.location.reload()
    },
  },
  computed: {
    storeName() {
      if (this.store) {
        return this.store.name
      }
      if (this.storeObj) {
        return this.storeObj.store_name
      }
      return ''
    },
    // otp驗證碼
    code() {
      return this.$route.query.code
    },
    storeIdFromStorage() {
      return this.$store.getters['member/storeId'];
    },
    storeObj() {
      const vm = this
      return this.$store.getters['member/memberStores'].filter((item) => {
        return item.store_id == vm.storeId
      })[0]
    },
    providerId() {
      return this.$route.params.providerId;
    },
    storeId() {
      return this.$route.params.storeId;
    },
  },
};
</script>
